// IMPORT ADDONS
//@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
// .shadow {
//     position: relative;
//
//     &::after {
//         content: '';
//         z-index: 0;
//         width: 100%;
//         top: 100%;
//         height: 40px;
//         position: absolute;
//         background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
//     }
// }


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
//@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

//@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
//@import 'modules/module-slider-light';
//@import 'modules/module-header';
//@import 'modules/module-soumission';






/*****************************************************/
/* INDEX.PHP
/*****************************************************/

section {
    padding-left: 7%;
    padding-right: 7%;
}

section.logo {
    padding-top: 70px;
    padding-bottom: 285px;
    .logo-wrapper {
        margin-right: 25%;
    }
    .title-wrapper {
        h3 {
            font-weight: 500;
            font-size: $font-size-30;
            color: $color-black;
        }
    }
    &.pageSite {
        padding-bottom: 135px;
        .logo-wrapper {
            margin-right: 0;
        }
        @media screen and (max-width: $size-sm-max){
            .title-wrapper {
                padding-bottom: 25px;
            }
        }
    }
    &.pageProjet {
        padding-top: 50px;
        padding-bottom: 50px;
        .logo-wrapper {
            margin-right: 0;
        }
        @media screen and (max-width: $size-sm-max){
            .title-wrapper {
                padding-bottom: 25px;
            }
        }
    }
    @media screen and (max-width: 1600px){
        .logo-wrapper {
            margin-right: 22%;
        }
        @media screen and (max-width: 1550px){
            .logo-wrapper {
                margin-right: 20%;
            }
            @media screen and (max-width: 1400px){
                .logo-wrapper {
                    margin-right: 18%;
                }
                @media screen and (max-width: $size-sm-max){
                    flex-direction: column;
                    .logo-wrapper {
                        margin: 0 0 50px 0;
                    }
                    @media screen and (max-width: $size-xs-max){
                        padding-bottom: 200px;
                        @media screen and (max-width: 375px){
                            .title-wrapper h3 {
                                font-size: 1.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section_title {
    padding-top: 100px;
    padding-bottom: 100px;
    h3 {
        color: $color-black;
        font-size: 4vw;
        font-weight: 800;
        text-align: center;
        &.title-pages {
            text-align: left;
        }
    }
    @media screen and (max-width: $size-xs-max){
        h3 {
            font-size: 26px;
        }
    }
}

div.arrow-back {
    a {
        font-size: 70px!important;
        color: $color-black;
    }
}

div.section_title_pages {
    padding: 0 5% 75px 7%;
    h3 {
        font-weight: 500;
        color: $color-black;
        font-size: 30px;
        padding-bottom: 45px;
    }
    p { 
        font-weight: normal;
        font-size: 18px;
        color: $color-black;
        line-height: 1.5;
    }
}

section.section-categories {
    .section-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        .category {
            h4 {
                font-weight: normal;
                font-size: $font-size-26;
                color: $color-black;
                border-bottom: 1px solid $color-black;
                padding-bottom: 2px;
                width: max-content;
            }
        }
    }

    div.item-wrapper {
        position: relative;
        overflow: hidden;
        .title {
            position: absolute;
            bottom: 10%;
            left: 10%;
            right: 0;
            h4 {
                color: $color-white;
                font-weight: bold;
                font-size: 30px;
            }
        }
        img {
            transition: .5s;
            transform: scale(1);
        }
        &:hover {
            img {
                transform: scale(1.05);
                transition: .5s;
            }
        }
    }
    @media screen and (max-width: $size-sm-max){
        .section-wrapper {
            grid-template-columns: 1fr;
            grid-row-gap: 30px;
        }
    }
}

section.icons {
    h3 {
        font-weight: 500;
        font-size: $font-size-40;
        color: #080808;
        line-height: 1.2;
        padding-bottom: 80px;
        @media screen and (max-width: $size-xs-max) {
            text-align: center;
            padding-bottom: 0;
            br {
                display: none;
            }
        }
    }
    .main-content {
        position: relative; 
        .owl-nav {
            position: absolute;
            top: -100px;
            z-index: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: $size-xs-max) {
                display: none;
            }
        }   
    } 
}


section.section-fiche {
    .fiche-type {
        font-weight: normal;
        color: $color-black;
        font-size: 30px;
        padding-bottom: 30px;
    }
    .link-mobile {
        a {
            color: $color-black;
            font-weight: normal;
            font-size: 22px;
        }
        @media screen and (max-width: $size-xs-max){
            padding-bottom: 30px;
            a {
                font-size: 20px;
            }
        }
    }
    .link {
        margin-bottom: 30px;
        a {
            color: $color-black;
            font-weight: normal;
            font-size: 24px;
            border-bottom: 1px solid $color-black;
        }
        @media screen and (max-width: $size-xs-max){
            a {
                font-size: 20px;
            }
        }
    }
    .link-more {
        a {
            color: $color-black;
            font-weight: normal;
            font-size: 24px;
            width: fit-content;
            border-bottom: 1px solid $color-black;
        }
        a.links-ext {
            font-weight: normal;
            font-size: 22px;
            color: $color-black;
            border-bottom: none;
        }
        @media screen and (max-width: $size-xs-max){
            a {
                font-size: 20px;
                border-bottom: none;
                text-align: center;
                margin-bottom: 35px;
            }
            a.links-ext {
                font-size: 20px;
            }
        }
    }
}


section.modele_demo_m {
    background-color: #f9fafa;
    .mobile-view {
        width: 100%;
        display: flex;
        justify-content: center;
        & > .view-wrapper {
            position: relative;
            margin: 60px auto;
            height: 690px;
            position: relative;
            transform: translateX(0);
            transition-duration: .3s;
            transition-property: transform;
            transition-timing-function: cubic-bezier(.25,1,.5,1);
            width: 382px;

            & > .phone-style-bar {
              background-color: #eff1f2;
              border-radius: 1.5px;
              height: 3px;
              left: 50%;
              position: absolute;
              top: 23px;
              transform: translateX(-50%) translateY(-50%);
              width: 24px;
            }

            &::before {
                background: #fff;
                box-shadow: -15px 8px 20px -10px rgba(0,0,0,.2);
                content: "";
                display: inline-block;
                height: 62px;
                width: 100%;
                border-radius: 46px 46px 0 0;
                margin-bottom: -3px;
            }
            &::after {
                background: #fff;
                box-shadow: -15px 8px 20px -10px rgba(0,0,0,.2);
                content: "";
                display: inline-block;
                height: 62px;
                width: 100%;
                border-radius: 0 0 46px 46px;
                margin-top: -3px;
            }
        }
    }
}








/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {


}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}

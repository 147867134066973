footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 7%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .section-copyright {
            .copyright {
                font-weight: normal;
                font-size: 14px;
                color: $color-white;
                opacity: 0.5;
                margin-bottom: 17px;
            }
        }

        @media screen and (max-width: $size-sm-max){
            flex-direction: column-reverse;
            align-items: initial;
            .section-center {
                padding: 35px 0;
            }
            @media screen and (max-width: $size-xs-max){


            }
        }
    }
    @media screen and (max-width: $size-sm-max){
        min-height: 250px;
        @media screen and (max-width: $size-xs-max){
            min-height: 275px;
        }
    }
}
